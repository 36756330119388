import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, HostListener } from '@angular/core';
import { UserPortalMessageFormat } from 'src/app/header/user-portal-message-interface';
import { NxgHeaderSidenavService } from 'src/app/header-mbf-sidenav/nxg-header-sidenav/nxg-header-sidenav.service';
import { HeaderService } from 'src/app/header/header.service';

@Component({
  selector: 'app-header-sidenav-notifications',
  templateUrl: './header-sidenav-notifications.component.html',
  styleUrls: ['./header-sidenav-notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderSidenavNotificationsComponent implements OnInit {
  notifications: UserPortalMessageFormat[] = [] as UserPortalMessageFormat[];
  undismissedNotificationsAvailable = false;
  unreadMessages: UserPortalMessageFormat[] = [];
  windowHeight = window.innerHeight;

  constructor(public headerSidenavService: NxgHeaderSidenavService,
              public headerService: HeaderService,
              public cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.windowHeight = window.innerHeight;
    this.headerSidenavService.getHeaderNotifications().subscribe(res => {
      this.notifications = res;
      this.cd.markForCheck();
     });
    this.headerService.undismissedNotificationsAvailable.subscribe((data) => {
      this.undismissedNotificationsAvailable = data;
      this.cd.markForCheck();
    });
    this.headerSidenavService.getHeaderUnreadMessages().subscribe(res => {
      this.unreadMessages = res;
      this.cd.markForCheck();
     });
  }
  openNotificationDetailsDialog(action: string, item: UserPortalMessageFormat)  {
    this.headerSidenavService.openNotificationDetailsDialog(action, item);
    this.cd.markForCheck();
  }
  clearUpdates(){
    this.headerSidenavService.clearUpdates();
  }
  getNotificationsHeader(messageType: string): string {
    return this.headerService.getNotificationsHeader(messageType);
  }
  deleteNotification(item: UserPortalMessageFormat): void {
    this.headerService.deleteNotification(item.messageId).subscribe((response: any) => {
      //this.getNotifications(false);
    });
    this.cd.markForCheck();
  }
  replaceMethod(str, title) {
    let msgText = str.replace(/<[^>]*>/g, '');
    if(msgText === null || msgText === undefined || msgText === '') {
      msgText = title;
    }
    return msgText;
  }
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.windowHeight = window.innerHeight;
  }
}
