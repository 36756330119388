<div [ngClass]="action=='Contact' ||
action == 'cancelBrandConfirmation'?'adding-brand-style':'' ||
action == 'deleteBrandLogo'?'adding-brand-style':'' ||
action == 'savedBrandLogo'?'adding-brand-style':'' ||
action == 'wrongImageSize'?'adding-brand-style':'' ||
action == 'brandAlreadyExists'?'adding-brand-style':'' ||
action == 'ServiceUnavailable'?'adding-brand-style':'' ||
action =='Details'?'contact-style' :'action-style'">
    <h2 mat-dialog-title *ngIf="action !='Delete' && action !='Publish'" class="m-0">
        <span *ngIf="action =='Preview'">Preview</span>
        <span *ngIf="action =='Contact'">Contact Information</span>
        <!-- <span *ngIf="action =='Applications'">Applications</span>
        <span *ngIf="action =='DisplayMessageDates'" class="font-meso font-medium text-px-20">Data & Services Catalog
            Message Dates</span> -->
    </h2>
    <div class="flex justify-between">
        <div *ngIf="dialogData.brand && action !== 'Applications'" class="titleForNotification">
            <img class="brand-logo" *ngIf="dialogData.brand.logo !== null"
            src="{{apiPath + dialogData.brand.logo}}">
        </div>
        <div *ngIf="action =='Details'" class="mt-8 ml-auto font-semibold preview-notifType">
            <span class="messageTypeStyle uppercase">{{ getNotificationsHeader(dialogData.messageType)}}
            </span>
        </div>
    </div>
    <div *ngIf="action =='Details'" class="mt-8 font-bold titleForNotification"> {{ dialogData.messageTitle }}
    </div>
    <mat-dialog-content [ngClass]="action=='Preview' ||  action =='Contact' ||
    action == 'cancelBrandConfirmation'?'adding-brand-style':'' ||
    action == 'deleteBrandLogo'?'adding-brand-style':'' ||
    action == 'savedBrandLogo'?'adding-brand-style':'' ||
    action == 'wrongImageSize'?'adding-brand-style':'' ||
    action == 'brandAlreadyExists'?'adding-brand-style':'' ||
    action == 'ServiceUnavailable'?'adding-brand-style':'' ||
    action !='Details'?'' :'mt-20px'">
        <div *ngIf="(action != 'Delete' && action !='Publish'); else elseTemplate">
            <div *ngIf="action =='Preview' || action =='Details'">
                <span *ngIf="action =='Preview'" class="text-px-14">{{dialogData.messageTitle}}<br></span>
                <br *ngIf="action !='Details'">
                <p *ngIf="dialogData.messageText != null && dialogData.contactInformation != null">
                    <iframe frameborder="0" class="iframeContent" width="100%"
                        [appTinymceRichTextContent]="dialogData.messageText + dialogData.contactInformation">
                    </iframe>
                </p>
                <p *ngIf="dialogData.contactInformation == null">
                    <iframe frameborder="0" class="iframeContent" width="100%"
                        [appTinymceRichTextContent]="dialogData.messageText">
                    </iframe>
                </p>
                <p *ngIf="dialogData.messageText == null">
                    <iframe frameborder="0" class="iframeContent" width="100%"
                        [appTinymceRichTextContent]="dialogData.messageText">
                    </iframe>
                </p>
            </div>
            <div *ngIf="action =='Contact'">
                <p>
                    <iframe frameborder="0" width="100%" [appTinymceRichTextContent]="dialogData.contactInformation">
                    </iframe>
                </p>
            </div>
            <div class="text-px-14" *ngIf="action =='Applications'">
                This system message applies to {{dialogData.widgets.length}} applications.
                <br><br>
                <div *ngIf="dialogData.widgets.length > 0">
                    <div *ngFor="let filterGroups of dialogData.filterValue.groups">
                        <span *ngFor="let condition of filterGroups.conditions">
                            <span *ngIf="condition.type === 'application'">
                                <p>Included : </p>
                                <p class="pl-6" *ngFor="let widget of condition.values">{{widget.name}}</p>
                            </span>
                        </span>
                        <span *ngFor="let exception of filterGroups.exceptions">
                            <span *ngIf="exception.type === 'application'">
                                <p>Excluded : </p>
                                <p class="pl-6" *ngFor="let widget of exception.values">{{widget.name}}</p>
                            </span>
                        </span>

                    </div>
                </div>
            </div>
            <div *ngIf="action == 'CancelConfirmation'">
                <mat-icon class="text-px-60">error_outline</mat-icon>
                <h2 class="text-px-18">Cancel System Message?</h2>
                <p class="text-px-14 message-padding">Would you like to cancel editing of the system message?</p>
            </div>
            <div *ngIf="action =='Details'" class="w-full flex justify-between items-center">
                <div class="text-px-14 titleForNotification flex justify-items-start items-center">
                  <span>Posted: &nbsp;{{dialogData.createdDateTime | date:'medium'}}</span>
                </div>
                <div *ngIf="dialogData.lastUpdatedDateTime" class="text-px-14 titleForNotification flex justify-items-end items-center mr-8">
                  <span>Last Update: &nbsp;{{dialogData.lastUpdatedDateTime | date:'medium'}}</span>
                </div>
            </div>
            <div *ngIf="action == 'cancelBrandConfirmation' && !saveBtnDisabled">
                <p class="brand-img-txt message-padding">WARNING: Unsaved data will be lost</p>
            </div>
            <div *ngIf="action == 'cancelBrandConfirmation' && saveBtnDisabled">
                <p class="brand-img-txt message-padding">WARNING: This will cancel adding or editing of Brand Data</p>
            </div>
            <div *ngIf="action == 'deleteBrandLogo'">
                <p class="brand-img-txt message-padding">WARNING: This will delete the logo graphic</p>
            </div>
            <div *ngIf="action == 'savedBrandLogo'">
                <p class="brand-img-txt message-padding">Brand data has been saved</p>
            </div>
            <div *ngIf="action == 'wrongImageSize'">
                <p class="brand-img-txt message-padding">WARNING: Uploaded file is not a valid image. The only approved file type is PNG, and it must have dimensions of 250 x 150 pixels.</p>
            </div>
            <div *ngIf="action == 'brandAlreadyExists'">
                <p class="brand-img-txt message-padding">WARNING: This brand already exists. Please try again with
                    different brand name.</p>
            </div>

        </div>

        <ng-template #elseTemplate>
            <div *ngIf="(action == 'Delete' ) ; else elsePublishTemplate" class="text-px-18 message-padding c-black">
                <p>Are you sure you want to {{action.toLowerCase()}} this system message?</p>
                <p>This change cannot be undone.</p>
            </div>
            <ng-template #elsePublishTemplate>
                <mat-icon class="text-px-60">error_outline</mat-icon>
                <p class="text-px-14 message-padding">Are you sure you want to {{action.toLowerCase()}} this system
                    message?
                </p>
            </ng-template>
        </ng-template>
    </mat-dialog-content>

    <mat-dialog-content class="sys-msg-dialog">

        <div *ngIf="action == 'ServiceUnavailable'">
            <h2 class="text-px-18">System Update</h2>
            <p class="text-px-14 message-padding ">Please try again later, this will take a few minutes.</p>
        </div>

    </mat-dialog-content>
    <!-- <mat-dialog-content *ngIf="action =='DisplayMessageDates'">
        <div class="text-px-16 c-title">
            <p class="pt-4">Start Time: {{dialogData.effectiveDateTime | date:'full'}}</p>
            <p>End Time: {{dialogData.expiresDateTime | date:'full'}}</p>
            <p class="pt-4 pb-4">Applications affected: {{dialogData.widgets.length}}
                <a *ngIf="dialogData.widgets.length > 0" href="javascript:void(0)"
                    (click)="viewAffectedApplications('Applications', dialogData)" class="text-px-12 c-blue pl-4"
                    (click)="$event.stopPropagation()">View affected applications</a>
            </p>
            <p class="pt-4 pb-4">Last Update: {{dialogData.lastUpdatedDateTime | date:'full'}}</p>
        </div>
    </mat-dialog-content> -->

    <mat-dialog-actions
        [ngClass]="action !='Details' || (action =='Details' && dialogData.messageType != 'USER_NOTIFICATION') ? 'dialog-style' : 'notification-style'">
        <button mat-button color="accent" tabindex="-1" class="uppercase"
            *ngIf="action == 'Details' && dialogData.messageType == 'USER_NOTIFICATION'"
            (click)="doAction()">Delete</button>
        <button tabindex="-1" mat-button color="accent" class="uppercase"
            *ngIf="(action == 'Delete' || action == 'Publish')" (click)="closeDialog()">Cancel</button>
        <button tabindex="-1" mat-button color="accent" class="uppercase" (click)="doAction()"
            *ngIf="action == 'Publish'">{{action}}</button>
        <button tabindex="-1" mat-flat-button color="accent" class="uppercase" (click)="doAction()"
            *ngIf="action == 'Delete'"><span class="font-semibold">{{action}}</span></button>
        <button tabindex="-1" mat-button color="accent" class="uppercase" (click)="closeDialog()"
            *ngIf="action !='Delete' && action !='Publish' && action != 'CancelConfirmation' && action != 'Details' && action != 'ServiceUnavailable'
        && action != 'DisplayMessageDates' && action !='cancelBrandConfirmation' && action !='deleteBrandLogo' && action != 'savedBrandLogo' && action != 'wrongImageSize' && action != 'brandAlreadyExists'"
            [routerLink]="['/Admin/SystemMessageAdministration/Edit/', dialogData.messageId]">Edit</button>
        <button tabindex="-1" mat-button color="accent" class="uppercase"
            *ngIf="action != 'Delete'&& action !='Publish'  && action != 'ServiceUnavailable'
        && action != 'DisplayMessageDates' && action !='cancelBrandConfirmation' && action !='deleteBrandLogo' && action != 'savedBrandLogo' && action != 'wrongImageSize' && action != 'brandAlreadyExists' && action == 'Details'"
            (click)="closeDialog()">Close</button>
        <button tabindex="-1" mat-button color="accent" class="uppercase"
            *ngIf="action != 'Delete'&& action !='Publish'  && action != 'ServiceUnavailable'
        && action != 'DisplayMessageDates' && action !='cancelBrandConfirmation' && action !='deleteBrandLogo' && action != 'savedBrandLogo' && action != 'wrongImageSize' && action != 'brandAlreadyExists' && action != 'Details'"
            (click)="closeDialog()">No</button>
        <button tabindex="-1" mat-button color="accent" class="uppercase" *ngIf="action == 'CancelConfirmation'"
            [routerLink]="['/Admin/SystemMessageAdministration/Display']" (click)="closeDialog()">Yes</button>
        <!-- <button tabindex="-1" mat-button color="accent" class="uppercase" *ngIf="action == 'DisplayMessageDates'"
            (click)="closeDialog()">Dismiss</button> -->
        <button tabindex="-1" mat-button color="accent" class="uppercase" *ngIf="action == 'cancelBrandConfirmation'"
            (click)="closeDialogAndCloseBrand()">Cancel</button>
        <button tabindex="-1" mat-button color="accent" class="uppercase sys_msg_btn"
            *ngIf="action == 'cancelBrandConfirmation' && !this.saveBtnDisabled"
            (click)="closeDialogAndSaveBrand()">Save</button>
        <button tabindex="-1" mat-button color="accent" class="uppercase" *ngIf="action == 'deleteBrandLogo'"
            (click)="closeDialog()">Cancel</button>
        <button tabindex="-1" mat-button color="accent" class="uppercase sys_msg_btn"
            *ngIf="action == 'deleteBrandLogo'" (click)="closeDialogAndDeleteImage()">Delete</button>
        <button tabindex="-1" mat-button color="accent" class="uppercase sys_msg_btn" *ngIf="action == 'savedBrandLogo'"
            (click)="closeDialogAndCloseBrand()">Ok</button>
        <button tabindex="-1" mat-button color="accent" class="uppercase sys_msg_btn" *ngIf="action == 'wrongImageSize'"
            (click)="closeDialog()">Dismiss</button>
        <button tabindex="-1" mat-button color="accent" class="uppercase sys_msg_btn"
            *ngIf="action == 'brandAlreadyExists'" (click)="closeDialog()">Dismiss</button>

    </mat-dialog-actions>

    <mat-dialog-actions
        [ngClass]="action !='Details' || (action=='Details' && dialogData.messageType !='USER_NOTIFICATION' ) ? 'dialog-style' : 'notification-style' ">

        <button tabindex="-1" mat-button class="uppercase sys_msg_btn" *ngIf="action == 'ServiceUnavailable'"
            (click)="closeDialog()">OKAY</button>

    </mat-dialog-actions>
</div>
