import { Injectable } from '@angular/core';
import { UserApiService } from 'src/app/user/user-api.service';
import { User } from 'src/app/user/user';
import { UserRolesItem } from 'src/app/user/user-roles-item';
import { UserPortalMessageFormat } from 'src/app/header/user-portal-message-interface';
import { PollingTimerService } from 'src/app/services/shared/polling-timer/polling-timer.service';
import { PollingItem } from 'src/app/services/shared/polling-timer/polling-interface';
import { memoize, values } from 'lodash';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HeaderService } from 'src/app/header/header.service';
import { HeaderData, HeaderMenu, HeaderSubmenu } from 'src/app/header/header-data';
import { AppConfigService } from 'src/app/core/app-config.service';
import { BehaviorSubject, Observable, throwError, of, Subject } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NotificationPopupComponent } from 'src/app/admin/system-message/components/system-messages-display-view/notification-popup/notification-popup.component';

@Injectable({
  providedIn: 'root'
})
export class NxgHeaderSidenavService {
  user: User = {
    firstName: '',
    lastName: '',
    userRoles: [] as UserRolesItem[]
  } as User;
  notifications: UserPortalMessageFormat[] = [] as UserPortalMessageFormat[];
  private headerNotifications = new BehaviorSubject<UserPortalMessageFormat[]>([]);
  readonly obsNotifications = this.headerNotifications.asObservable();
  private headerUnreadMessages = new BehaviorSubject<UserPortalMessageFormat[]>([]);
  readonly obsUnreadMessages = this.headerUnreadMessages.asObservable();
  private isUnreadNotificationsAvailable = new BehaviorSubject<boolean>(false);
  readonly obsisUnreadNotificationsAvailable = this.isUnreadNotificationsAvailable.asObservable();
  unreadMessages: UserPortalMessageFormat[] = [];
  userNotificationMessages: UserPortalMessageFormat[] = [];
  unreadNotifications = 0;
  unreadNotificationsAvailable = false;
  undismissedNotificationsAvailable = false;
  showSnackbar = true;
  isRefresh = false;
  envConfig = {};
  private defaultMenu = new BehaviorSubject<any>([]);
  readonly obsDefaultMenu = this.defaultMenu.asObservable();
  nMenu: any = [];
  private navigationMenu = new BehaviorSubject<any>([]);
  readonly obsNavigationMenu = this.navigationMenu.asObservable();
  tempMenu: any =[];
  private navMenu = new BehaviorSubject<any>([]);
  readonly obsNavMenu = this.navMenu.asObservable();
  pollingItems: PollingItem[] = [{ key: 'notifications', callback: () => { this.refreshUserAndWorkspaces(); } }];
  memoizeGetmessageType = memoize(this.getNotificationsHeader, (...args) => values(args).join(''));
  memoizeGetBuildUrl = memoize((item: HeaderSubmenu) => this.buildUrl(item), (...args) => args[0].title);

  constructor( public userApiService: UserApiService,
               public snackBar: MatSnackBar,
               public headerService: HeaderService,
               public appConfigService: AppConfigService,
               private pollingTimerService: PollingTimerService,
               public matDialog: MatDialog,
  ) {
    this.getHeaderMenu();
    this.appConfigService.envConfig.subscribe(config =>
      { this.envConfig = config; }
     );
    this.refreshUserAndWorkspaces();
    this.pollingTimerService.registerPolling(this.pollingItems);
  }
  refreshUserAndWorkspaces(): void {
    this.userApiService.getUserAndWorkspace().subscribe((user: User) => {
      this.user = user;
      this.showSnackbar = true;
      this.snackBar.dismiss();
      this.getNotifications();
      this.headerService.setNotificationsUpdated(true);
    });
  }
  getHeaderMenu() {
    this.appConfigService.fetchComponentConfig('header-sidenavbar-mobile').subscribe((result: any) => {
      this.setDefaultMenu(result.default);
      this.nMenu = result.navigationMenu;
      this.nMenu = this.nMenu
          .filter(item => !(item.title === `APIs` && this.envConfig[`airlineCode`] !== `TBC`));
      this.setNavigationMenu(this.nMenu);
      this.tempMenu = this.nMenu.filter(item => item.title !== 'Actions');
      this.setNavMenu(this.tempMenu);
    });
  }
  /**
   * Method to fetch the notifications
   */
  getNotifications(): void {
    this.headerService.getActiveNotifications(false).subscribe((response: any) => {
      this.notifications = [];
      this.unreadMessages = [];
      this.userNotificationMessages = [];
      const unreadNotifications: UserPortalMessageFormat[] = [];
      const plannedSystemMessages: UserPortalMessageFormat[] = [];
      const unPlannedSystemMessages: UserPortalMessageFormat[] = [];
      const infoSystemMessages: UserPortalMessageFormat[] = [];
      if (response) {
        for (const notification of response) {
          if (!notification.messageRead) {
            unreadNotifications.push(notification);
          }
          if (notification.messageType === 'UNPLANNED_OUTAGE') {
            unPlannedSystemMessages.push(notification);
          } else if (notification.messageType === 'PLANNED_OUTAGE') {
            plannedSystemMessages.push(notification);
          } else if (notification.messageType === 'INFORMATIONAL') {
            infoSystemMessages.push(notification);
          } else if (notification.messageType === 'USER_NOTIFICATION') {
            this.userNotificationMessages.push(notification);
          } else if (notification.messageType === 'TRAINING') {
            this.userNotificationMessages.push(notification);
          } else if (notification.messageType === 'ACTION_REQUIRED') {
            this.userNotificationMessages.push(notification);
          }
        }
      }
      this.notifications = unPlannedSystemMessages.concat(plannedSystemMessages)
        .concat(infoSystemMessages).concat(this.userNotificationMessages);
      this.setHeaderNotifications(this.notifications);
      this.unreadMessages = unreadNotifications;
      this.setHeaderUnreadMessages(this.unreadMessages);
      this.updateCount();
      this.isRefresh = false;
    });
  }

  updateCount(): void {
    this.unreadNotifications = this.unreadMessages.length;
    this.unreadNotificationsAvailable = this.unreadNotifications > 0;
    this.setIsUnreadNotificationsAvailable(this.unreadNotificationsAvailable);
  }

  /**
   * Delete the User Notification
   * @param item Message Id
   */
  deleteNotification(item: UserPortalMessageFormat): void {
    this.isRefresh = true;
    this.headerService.deleteNotification(item.messageId).subscribe((response: any) => {
      this.getNotifications();
    });
  }

  openNotificationsOptions(): void {
    window.scroll({
      top: 0,
      left: 0
    });
    this.headerService.setopenNotificationData(true);
    this.headerService.setopenSideMenu(true);
  }

  /**
   * Method to get the Headers that should be displayed inside Notification drop down list
   * @param notificationDetails Notification details
   */
  getNotificationsHeader(messageType: string): string {
    return this.headerService.getNotificationsHeader(messageType);
  }

  clearUpdates(): void {
    this.isRefresh = true;
    const messageIds = this.unreadMessages.map(a => a.messageId);
    this.headerService.clearUpdates(messageIds).subscribe((response: any) => {
      this.getNotifications();
    });
  }
  buildUrl(item: HeaderSubmenu) {
    let url = item.url;
    if (item.title === this.appConfigService.getProperty(`mbfSupportPageTitle`)) {
      url = `#/Platform/psp/${this.envConfig['mbfPspId']}`;
    }
    if (item.title === 'Access Administrator Support') {
      url = this.buildAccessAdminLink(item);
    }
    if (item.title === 'Product and Data Access (Access Summary)') {
      url = this.buildAccessSummaryLink();
    }
    if (item.title === 'Introduction to MBF') {
      // tslint:disable-next-line: no-string-literal
      url = this.envConfig['mbfIntroUrl'];
    }
    return this.buildUrlFromString(url, item.type);
  }
  buildAccessAdminLink(item: HeaderSubmenu) {
    let url = '';
    const accessAdminUrl = 'accessAdminUrl';
    const mbfAdminAccessId = this.envConfig[accessAdminUrl];
    url = `#/Platform/psp/${mbfAdminAccessId}`;
    return url;
  }
  buildAccessSummaryLink() {
    let url = '';
    const accessSummaryUrl = 'accessSummaryUrl';
    url = this.envConfig[accessSummaryUrl];
    return url;
  }

  buildUrlFromString(url: string = '', type: string) {
    return this.headerService.buildUrlFromString(url, type, this.envConfig);
  }
  buildMbfSupportPageUrl(item: HeaderSubmenu): string {
    let url = '';
    const mbfPspIdString = 'mbfPspId';
    const mbfPspId = this.envConfig[mbfPspIdString];
    switch (this.appConfigService.getProperty(`mbfSupportPageTarget`)) {
      case 'portal_ng':
        item.type = 'portal_ng';
        url = `index.html#/Platform/apps/${mbfPspId}`;
        break;
      case 'portal-ui':
      default:
        item.type = 'portal-ui';
        url = `#/Platform/psp/${mbfPspId}`;
        break;
    }
    return url;
  }
  setHeaderNotifications(notifications: UserPortalMessageFormat[]) {
    return this.headerNotifications.next(notifications);
}
getHeaderNotifications(): Observable<UserPortalMessageFormat[]> {
    return this.headerNotifications;
}
setHeaderUnreadMessages(notifications: UserPortalMessageFormat[]) {
  return this.headerUnreadMessages.next(notifications);
}
getHeaderUnreadMessages(): Observable<UserPortalMessageFormat[]> {
  return this.headerUnreadMessages;
}
setIsUnreadNotificationsAvailable(val: boolean) {
  return this.isUnreadNotificationsAvailable.next(val);
}
getIsUnreadNotificationsAvailable(): Observable<boolean> {
  return this.isUnreadNotificationsAvailable;
}
getDefaultMenu(): Observable<any> {
  return this.defaultMenu;
}
setDefaultMenu(val: any) {
  return this.defaultMenu.next(val);
}
getNavigationMenu(): Observable<any> {
  return this.navigationMenu;
}
setNavigationMenu(val: any) {
  return this.navigationMenu.next(val);
}
getNavMenu(): Observable<any> {
  return this.navMenu;
}
setNavMenu(val: any) {
  return this.navMenu.next(val);
}
/*
/*
   * Method to handle Notification details pop up
   * @param action Type of Action from Dialog
   * @param item Notification details
   */
openNotificationDetailsDialog(action: string, item: UserPortalMessageFormat) {
  // this.closeSideMenu();
  const dataToDialog: any = item;
  dataToDialog.action = action;
  let notificationPanelClass='';
      if(item.messageType === 'UNPLANNED_OUTAGE') {
        notificationPanelClass = 'unplanned-notification-dialog-container';
      }
      else if (item.messageType === 'PLANNED_OUTAGE') {
        notificationPanelClass = 'planned-notification-dialog-container';
      }
      else if(item.messageType === 'ACTION_REQUIRED') {
        notificationPanelClass = 'actionReq-notification-dialog-container';
      }
      else if (item.messageType === 'TRAINING') {
        notificationPanelClass = 'training-notification-dialog-container';
      }
      else if(item.messageType === 'INFORMATIONAL' || item.messageType === 'USER_NOTIFICATION') {
        notificationPanelClass = 'info-notification-dialog-container';
      }
      if(item.messageType === 'USER_NOTIFICATION') {
              const dialogRef = this.matDialog.open(NotificationPopupComponent, {
                data: dataToDialog,
                width: '800px',
                maxWidth: '80vw',
                panelClass: notificationPanelClass
              });
              dialogRef['_overlayRef'].overlayElement.parentElement.style.cssText += 'z-index: 2000 !important';
          if (!item.messageRead) {
            this.headerService.markAsReadNotification(item.messageId).subscribe((response: any) => {
              this.getNotifications();
            });
          }
          dialogRef.afterClosed().subscribe(result => {
            if (result.event === 'Details') {
              this.deleteNotification(result.dialogData);
            }
          });
      }
      else {
            const dialogRef = this.matDialog.open(NotificationPopupComponent, {
              data: dataToDialog,
              width: '800px',
              maxWidth: '80vw',
              panelClass: notificationPanelClass
            });
            dialogRef['_overlayRef'].overlayElement.parentElement.style.cssText += 'z-index: 2000 !important';
        if (!item.messageRead) {
          this.headerService.markAsReadNotification(item.messageId).subscribe((response: any) => {
            this.getNotifications();
          });
        }
        dialogRef.afterClosed().subscribe(result => {
          if (result.event === 'Details') {
            this.deleteNotification(result.dialogData);
          }
        });
          }
}
}
