import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonUtilityService } from 'src/app/services/shared/common-utility.service';
import { SystemMessage } from '../../system-message-create-edit-view/system-message-interface';
import { SystemMessageService } from 'src/app/services/system-message/system-message.service';
import { AppConfigService } from 'src/app/core/app-config.service';
import { HeaderService } from 'src/app/header/header.service';

@Component({
  selector: 'app-nxg-system-messgae-preview',
  templateUrl: './nxg-system-messgae-preview.component.html',
  styleUrls: ['./nxg-system-messgae-preview.component.scss']
})
export class NxgSystemMessgaePreviewComponent implements OnInit {
  apiPath: any = '';
  @Input() sysMessage?: SystemMessage;
  @Input() source?: any='';
  @Output() onCloseDialog? = new EventEmitter<any>();
  @Output() onDelete? = new EventEmitter<any>();
  @Output() onClosePreview? = new EventEmitter<any>();

  constructor( private commonUtilityService: CommonUtilityService,
    public systemMessageService: SystemMessageService,
    private appServiceConfig: AppConfigService,
    public headerService: HeaderService) { 
    this.apiPath = this.appServiceConfig.getBackendPath('') + '/';
    if (this.sysMessage !== undefined && this.sysMessage.messageText !== null && this.sysMessage.contactInformation !== null){
       this.sysMessage.messageText = this.setApiPathForSystemMessageImgTags(this.sysMessage.messageText);
       this.sysMessage.contactInformation = this.setApiPathForSystemMessageImgTags(this.sysMessage.contactInformation);
    }
    }

  ngOnInit(): void {
    this.commonUtilityService.resizeIframe();
  }
  doAction() {
    this.onDelete.emit();
  }

  closeDialog() {
    if(this.source === 'sysMegDisplayPreview') {
      this.onClosePreview.emit();
    }else {
      this.onCloseDialog.emit();
    }
  }
  /**
   * Method to get the Headers that should be displayed inside Notification drop down list
   * @param notificationDetails Notification details
   */
  getNotificationsHeader(messageType: string): string {
    return this.headerService.getNotificationsHeader(messageType);
  }
  setApiPathForSystemMessageImgTags(systemMessageText){
    let updatedMessageText = '';
    const parser = new DOMParser();
    const doc = parser.parseFromString(systemMessageText, 'text/html');
    const imagelinks = doc.getElementsByTagName('img');
    const links = Array.from(imagelinks);
    for (let i = 0; i < links.length; i++) {
      let imgsrc = links[i].src;
      imgsrc = imgsrc.substring(imgsrc.indexOf('content'));
      imgsrc = this.apiPath + imgsrc;
      doc.images[i].src = imgsrc;
    }
    updatedMessageText = doc.body.innerHTML;
    return updatedMessageText;
  }

}
