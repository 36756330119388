<div class="flex flex-col snackbar-container">
  <div class="flex flex-row justify-between w-full snackbar-ipad-pro">
    <div>
      <span class="snackbar-title uppercase">{{
        getDisplayMsgType(notification.messageType)
      }}</span>
    </div>
    <div>
      <span class="snackbar-date">{{ notification.lastUpdatedDateTime | date: 'MMM dd' }} {{ '@' }}
        {{ notification.lastUpdatedDateTime | date: 'h:mm a' }}
      </span>
    </div>
  </div>
  <div class="flex flex-row items-center snackbar-ipad-pro">
    <div class="notification-icon-background">
      <span *ngIf="notification.messageType === 'UNPLANNED_OUTAGE'" class="material-symbols-outlined notification-icon notification-unplanned-color">
        warning</span>
    <span *ngIf="notification.messageType === 'PLANNED_OUTAGE'" class="material-symbols-outlined notification-icon notification-planned-color">
        flash_off</span>
    <span *ngIf="notification.messageType === 'INFORMATIONAL' || notification.messageType === 'USER_NOTIFICATION'" class="material-symbols-outlined notification-icon notification-information-color">
        info</span>
    <span *ngIf="notification.messageType === 'TRAINING'" class="material-symbols-outlined notification-icon notification-training-color">
        school</span>
      <span
        *ngIf="notification.messageType === 'ACTION_REQUIRED'"
        class="material-symbols-outlined notifican-icon notification-action-color">emergency_home</span>
    </div>
    <div class="messageText">
      <span class="truncate-summary">{{
        replaceMethod(notification.messageText, notification.messageTitle)
      }}</span>
    </div>
  </div>
  <div class="flex w-full justify-between action-wrapper snackbar-ipad-pro">
    <button mat-button class="action-buttons" (click)="learnMore()">
      <span class="action-button-text">LEARN MORE</span>
    </button>
    <button mat-button class="action-buttons" (click)="dismiss()">
      <span class="action-button-text">DISMISS</span>
    </button>
  </div>
</div>