<div>
    <div id="notification-cont" class="pb-5" 
    [ngClass]="{'notification-container':notifications.length > 0 ,'no-notifications':notifications.length === 0 }">
        <div class="notificationLine" mat-menu-item *ngFor="let item of notifications">
            <div class="notification-message-width"
                (click)="openNotificationDetailsDialog('Details', item)"
                [analyticTagging]="{'eventId':'notificationFlyout','srcCtrl':this}">
                <div class="flex flex-row items-center justify-between">
                    <div class="flex flex-col">
                        <div class="flex flex-row items-end notification-icon-wrapper notification-gap">
                            <div class="notificationHeading text-base" [ngClass]="{'read-Heading': item.messageRead }">
                                        <div class="icon-div">
                                                <span *ngIf="item.messageType === 'UNPLANNED_OUTAGE'" class="material-symbols-outlined notification-icon notification-unplanned-color">
                                                    warning</span>
                                                <span *ngIf="item.messageType === 'PLANNED_OUTAGE'" class="material-symbols-outlined notification-icon notification-planned-color">
                                                    flash_off</span>
                                                <span *ngIf="item.messageType === 'INFORMATIONAL' || item.messageType === 'USER_NOTIFICATION'" class="material-symbols-outlined notification-icon notification-information-color">
                                                    info</span>
                                                <span *ngIf="item.messageType === 'TRAINING'" class="material-symbols-outlined notification-icon notification-training-color">
                                                    school</span>
                                                <span *ngIf="item.messageType === 'ACTION_REQUIRED'" class="material-symbols-outlined notification-icon notification-action-color">
                                                    emergency_home</span>
                                        </div>
                            </div>
                            <div class="notification-type-text uppercase" [ngClass]="{'read-Heading': item.messageRead }">{{getNotificationsHeader(item.messageType)}}</div>
                        </div>
                        <div class="flex flex-row items-center pt-1">
                            <div class="notificationHeading notification-overflow-wrap" [ngClass]="{'read-Title': item.messageRead}">
                                <span class="truncate-summary">{{replaceMethod(item.messageText, item.messageTitle)}}</span>
                            </div>
                            
                        </div>
    
                    </div>
                </div>
            </div>
        </div>
        <div class="no-notification flex justify-center" *ngIf="notifications.length === 0">
            <span class="no-notifications-font pt-24">No Active Notifications or Alerts</span>
        </div>
    </div>
    <div *ngIf="unreadMessages.length > 0" class="clearUpdates" (click)="clearUpdates()">
        <span class="mark-as-read-label">Mark All As Read</span>
    </div>
</div>
