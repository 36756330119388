import { Component, OnInit, Optional, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonUtilityService } from 'src/app/services/shared/common-utility.service';
import { SystemMessage } from 'src/app/admin/system-message/components/system-message-create-edit-view/system-message-interface';
import { MatDialog } from '@angular/material/dialog';
import { SystemMessageService } from 'src/app/services/system-message/system-message.service';
import { AppConfigService } from 'src/app/core/app-config.service';
import { HeaderService } from 'src/app/header/header.service';
import { NxgSystemMessgaePreviewComponent } from 'src/app/admin/system-message/components/system-messages-display-view/nxg-system-messgae-preview/nxg-system-messgae-preview.component';


@Component({
  selector: 'app-notification-popup',
  templateUrl: './notification-popup.component.html',
  styleUrls: ['./notification-popup.component.scss']
})
export class NotificationPopupComponent implements OnInit {
  action: string;
  dialogData: SystemMessage;
  brandMode: any;
  apiPath: any = '';

  constructor(private commonUtilityService: CommonUtilityService,
    public dialogRef: MatDialogRef<NotificationPopupComponent>,
    public dialog: MatDialog,
    public systemMessageService: SystemMessageService,
    private appServiceConfig: AppConfigService,
    public headerService: HeaderService,
    // @Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
      this.action = data.action;
    this.dialogData = { ...data };
    this.apiPath = this.appServiceConfig.getBackendPath('') + '/';
    if (this.dialogData.messageText !== null && this.dialogData.contactInformation !== null){
       this.dialogData.messageText = this.setApiPathForSystemMessageImgTags(this.dialogData.messageText);
       this.dialogData.contactInformation = this.setApiPathForSystemMessageImgTags(this.dialogData.contactInformation);
    }
    this.brandMode = data.brandMode;
     }

  
     ngOnInit(): void {
      this.commonUtilityService.resizeIframe();
    }
    doAction() {
      this.dialogRef.close({ event: this.action, dialogData: this.dialogData });
    }
  
    closeDialog() {
      this.dialogRef.close({ event: 'Cancel' });
    }
    /**
   * Method to get the Headers that should be displayed inside Notification drop down list
   * @param notificationDetails Notification details
   */
  getNotificationsHeader(messageType: string): string {
    return this.headerService.getNotificationsHeader(messageType);
  }
  setApiPathForSystemMessageImgTags(systemMessageText){
    let updatedMessageText = '';
    const parser = new DOMParser();
    const doc = parser.parseFromString(systemMessageText, 'text/html');
    const imagelinks = doc.getElementsByTagName('img');
    const links = Array.from(imagelinks);
    for (let i = 0; i < links.length; i++) {
      let imgsrc = links[i].src;
      imgsrc = imgsrc.substring(imgsrc.indexOf('content'));
      imgsrc = this.apiPath + imgsrc;
      doc.images[i].src = imgsrc;
    }
    updatedMessageText = doc.body.innerHTML;
    return updatedMessageText;
  }

}
