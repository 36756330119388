<div class="w-full notification-wrapper" [ngClass]="{'preview-dialog-container': source === 'sysMessagePreview'}">
    <div class="flex items-center pl-16px mobile-alignment" [ngClass]="{'justify-between': sysMessage?.brand, 'justify-end pt-4': !sysMessage?.brand}">
        <div *ngIf="sysMessage?.brand">
            <img class="brand-logo" *ngIf="sysMessage.brand.logo !== null"
            src="{{apiPath + sysMessage.brand.logo}}">
        </div>
        <div class="flex items-end gap-8px" [ngClass]="{'mobile-padding': sysMessage?.brand}">
            <div class="notofication-icon-div notification-icon">
                    <span *ngIf="sysMessage?.messageType === 'UNPLANNED_OUTAGE'" class="material-symbols-outlined notification-unplanned-color">
                        warning</span>
                    <span *ngIf="sysMessage?.messageType === 'PLANNED_OUTAGE'" class="material-symbols-outlined notification-planned-color">
                        flash_off</span>
                    <span *ngIf="sysMessage?.messageType === 'INFORMATIONAL' || sysMessage?.messageType === 'USER_NOTIFICATION'" class="material-symbols-outlined notification-information-color">
                        info</span>
                    <span *ngIf="sysMessage?.messageType === 'TRAINING'" class="material-symbols-outlined notification-training-color">
                        school</span>
                    <span *ngIf="sysMessage?.messageType === 'ACTION_REQUIRED'" class="material-symbols-outlined notification-action-color">
                        emergency_home</span>
            </div>
            <div>
                <span class="messageTypeStyle">
                    {{ getNotificationsHeader(sysMessage.messageType) }}
                </span>
            </div>
            
        </div>
     </div>
     <div>
        <div class="pl-16px notification-title font-bold mt-4"> {{ sysMessage.messageTitle }}</div>
        <mat-dialog-content>
            <div class="msg-wrappper">
                <p *ngIf="sysMessage.messageText != null && sysMessage.contactInformation != null">
                    <iframe frameborder="0" class="iframeContent" width="100%"
                        [appTinymceRichTextContent]="sysMessage.messageText + sysMessage.contactInformation">
                    </iframe>
                </p>
                <p *ngIf="sysMessage.contactInformation == null">
                    <iframe frameborder="0" class="iframeContent" width="100%"
                        [appTinymceRichTextContent]="sysMessage.messageText">
                    </iframe>
                </p>
                <p *ngIf="sysMessage.messageText == null">
                    <iframe frameborder="0" class="iframeContent" width="100%"
                        [appTinymceRichTextContent]="sysMessage.contactInformation">
                    </iframe>
                </p>
            </div>
           
        </mat-dialog-content>
        
        <mat-dialog-actions class="pl-16px action-div flex flex-col" [ngClass]="{'action-div':source !== 'sysMessagePreview'}">
            <div class="flex justify-between items-center w-full mobile-alignment">
                <div *ngIf="sysMessage.createdDateTime" class="notification-date flex justify-items-start items-center">
                  <span>Posted: {{sysMessage.createdDateTime | date:'medium'}}</span>
                </div>
                <div *ngIf="sysMessage.lastUpdatedDateTime" class="notification-date flex justify-items-end items-center mobile-padding">
                  <span>Last Update: {{sysMessage.lastUpdatedDateTime | date:'medium'}}</span>
                </div>
            </div>
            <div class="w-full" *ngIf="source !== 'sysMessagePreview'">
                <mat-divider class="date-divider"></mat-divider>
            </div>
          
            <div class="w-full" *ngIf="source !== 'sysMessagePreview'">
                <div class="flex flex-row items-center w-full" [ngClass]="{'justify-between':sysMessage.messageType === 'USER_NOTIFICATION','justify-end': sysMessage.messageType !== 'USER_NOTIFICATION' }">
                    <div *ngIf="sysMessage.messageType == 'USER_NOTIFICATION'">
                        <button  mat-flat-button tabindex="-1" class="uppercase action-buttons"
                        (click)="doAction()"><span class="action-button-text">Delete</span></button>
                    </div>
                    <div>
                        <button tabindex="-1" mat-flat-button class="uppercase action-buttons" (click)="closeDialog()"><span class="action-button-text">Close</span></button>
                    </div>    
                </div>
            </div>
        </mat-dialog-actions>
    </div>
</div>
