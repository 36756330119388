import { Component, OnInit, Input, HostListener, NgZone, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { AppConfigService } from 'src/app/core/app-config.service';
import { memoize } from 'lodash';
import { HeaderSubmenu } from 'src/app/header/header-data';
import { HeaderService } from 'src/app/header/header.service';
import { User } from 'src/app/user/user';
import { UserRolesItem } from 'src/app/user/user-roles-item';
import { UserPortalMessageFormat } from 'src/app/header/user-portal-message-interface';
import { MatDialog} from '@angular/material/dialog';
import { AddFolderDialogComponent } from 'src/app/workspace-widget/basic-folder/add-folder-dialog/add-folder-dialog.component';
import { AddLinkDialogComponent } from 'src/app/workspace-widget/drag-drop/add-link-dialog/add-link-dialog.component';
import {
   ViewFolderArchiveDialogComponent } from 'src/app/workspace-options/view-folder-archive-dialog/view-folder-archive-dialog.component';
import {
  ResetHomeToDefaultDialogComponent
} from 'src/app/workspace-options/reset-home-to-default-dialog/reset-home-to-default-dialog.component';
import { SearchResultsService } from 'src/app/services/search-results/search-results.service';
import { Router } from '@angular/router';
import { SearchResultsTutorialDialogComponent } from 'src/app/header/search-results-tutorial-dialog/search-results-tutorial-dialog.component';
import { ApplistTutorialDialogComponent } from 'src/app/header/applist-tutorial-dialog/applist-tutorial-dialog.component';
import { TutorialDialogComponent } from 'src/app/header/tutorial-dialog/tutorial-dialog.component';
import { NxgHeaderSidenavService } from 'src/app/header-mbf-sidenav/nxg-header-sidenav/nxg-header-sidenav.service';
@Component({
  selector: 'app-header-mbf-sidenav-mobile-tab',
  templateUrl: './header-mbf-sidenav-mobile-tab.component.html',
  styleUrls: ['./header-mbf-sidenav-mobile-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderMbfSidenavMobileTabComponent implements OnInit {
  @Input() currentTab = '';
  @Input() envConfig = {};
  @Input() user: User = {
    firstName: '',
    lastName: '',
    userRoles: [] as UserRolesItem[]
  } as User;
  @Input() notifications: UserPortalMessageFormat[] = [] as UserPortalMessageFormat[];
  unreadMessages: UserPortalMessageFormat[] = [];
  userNotificationMessages: UserPortalMessageFormat[] = [];
  unreadNotifications = 0;
  unreadNotificationsAvailable = false;
  undismissedNotificationsAvailable = false;
  @Input() yourAccountLink = '';
  @Input() workspaceLocked = false;
  @Input() communitiesItems = [] as HeaderSubmenu[];
  @Input() accessAdministratorFlag = false;
  defaultMenu: any = [];
  navigationMenu: any = [];
  navMenu: any = [];
  selectedItem: any = {};
  displayNavigationTitle = false;
  // enableMyDashboardConfig = false;
  memoizeGetBuildUrl = memoize((item: HeaderSubmenu) => this.buildUrl(item), (...args) => args[0].title);
  isEnableSubMenu: boolean;
  selectMyDashboard = false;
  isMyDashboardChecked = false;
  isFeaturedProductsChecked = false;
  isMobile = false;
  constructor(
    public appConfigService: AppConfigService,
    public headerService: HeaderService,
    private zone: NgZone,
    public matDialog: MatDialog,
    private searchResultsService: SearchResultsService,
    public router: Router,
    public headerSidenavService: NxgHeaderSidenavService,
    public cd: ChangeDetectorRef
  ) {
     
   }

  ngOnInit(): void {
    this.getHeaderMenu();
    this.isEnableSubMenu = false;
    this.headerService.setSelectedNavigation('Default');
    this.headerSidenavService.getHeaderUnreadMessages().subscribe(res => {
      this.unreadMessages = res;
      this.unreadNotifications = this.unreadMessages.length;
      this.cd.markForCheck();
     });
    this.headerSidenavService.getIsUnreadNotificationsAvailable().subscribe((data) => {
      this.unreadNotificationsAvailable = data;
      this.cd.markForCheck();
    });
  }
  getHeaderMenu() {
    this.headerSidenavService.getDefaultMenu().subscribe((data) => {
      this.defaultMenu = data;
      this.cd.markForCheck();
    });
    this.headerSidenavService.getNavigationMenu().subscribe((data) => {
      this.navigationMenu = data;
      this.cd.markForCheck();
    });
    this.headerSidenavService.getNavMenu().subscribe((data) => {
      this.navMenu = data;
      this.cd.markForCheck();
    });
  }
  onChange(e) {
    // here u are again setting the input value as true.
    e.checked = true;
  }

  selectNavigation(item) {
    this.selectedItem = item;
    this.displayNavigationTitle = true;
    this.headerService.setSelectedNavigation(item.title);
  }
  buildUrl(item: HeaderSubmenu) {
    return this.headerSidenavService.buildUrl(item);
  }

  ifAdminLink(item: HeaderSubmenu) {
    return this.showAdminLinkfromString(item.title);
  }
  showAdminLinkfromString(title: string = '') {
    if (title.toLowerCase() === 'admin') {
      if (this.user.userRoles.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
  navigateToDefaultMenu() {
    this.selectedItem = {};
    this.displayNavigationTitle = false;
    this.headerService.setSelectedNavigation('Default');
  }
  closeSideMenu(): void {
    this.headerService.setopenSideMenu(false);
    this.headerService.setopenNotificationData(false);
    this.displayNavigationTitle = false;
    this.selectedItem = {};
  }
  // enableHomePageConfig() {
  //   this.enableMyDashboardConfig = !this.enableMyDashboardConfig;
  // }
  enablesuperSubMenu(){
    this.isEnableSubMenu =  !this.isEnableSubMenu;
  }
  checkCheckBoxvalue(event, option) {
    if (option === 'myDashboard') {
     this.headerService.setDashboardChecked(event.checked);
    }
    if (option === 'banner') {
      this.headerService.setBannerChecked(event.checked);
    }
    if (this.checkMobile()) {
      this.closeSideMenu();
     }
  }
  getSideNavConfigOptions() {
    this.headerService.getDashboardChecked().subscribe(response => {
      this.isMyDashboardChecked = response;
    });
    this.headerService.getBannerChecked().subscribe(response => {
      this.isFeaturedProductsChecked = response;
    });
  }
  isSubMenuClicked(isSubMenuClicked){
    if (isSubMenuClicked === true && this.isMobile === true) {
      this.closeSideMenu();
    }
  }
  checkMobile() {
    if (window.innerWidth <= 991) {
      if (this.isMobile !== true) {
        this.zone.run(() => {
          this.isMobile = true;
        });
      }
      return true;
    } else {
      if (this.isMobile !== false) {
        this.zone.run(() => {
          this.isMobile = false;
        });
      }
      return false;
    }
  }
  openDashboardDialog(item: HeaderSubmenu) {
    this.closeSideMenu();
    if (item.title === 'Add a folder') {
      const obj = {
        action: 'Add'
      };
      this.matDialog.open(AddFolderDialogComponent, {
        data: obj,
        disableClose: true
      });
    } else if (item.title === 'Add a link') {
      const obj = {
        action: 'Add'
      };
      this.matDialog.open(AddLinkDialogComponent, {
        data: obj,
        disableClose: true
      });
    } else if (item.title === 'View folder archive') {
      this.matDialog.open(ViewFolderArchiveDialogComponent);
    } else if (item.title === 'Reset home page to default') {
      this.matDialog.open(ResetHomeToDefaultDialogComponent, {
        disableClose: true,
        autoFocus: false
      });
    }
  }
  displayItem(item) {
    if (item.title === 'My Field Service Office') {
       if (!(this.user && this.user.airlineCode && this.user.airlineCode === 'TBC')) {
        return true;
       }
       else {
        return false;
       }
    }
    else if (item.title === 'Access Administrator Support') {
      if (this.accessAdministratorFlag) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return true;
    }
  }
  openTutorialDialog(obj) {
    if (window.location.href.includes('/Platform/searchResults')) {
      this.searchResultsService.setTextSearchRequest('Training');
      this.router.navigate(['Platform/searchResults']);
      const dialogRef = this.matDialog.open(SearchResultsTutorialDialogComponent, {
        data: obj, panelClass: 'search-tutorial-dialog-cls',
        disableClose: true, id: 'search-tutorial-dlg'
      });
    } else if (window.location.href.includes('/Platform/apps')) {
      this.matDialog.open(ApplistTutorialDialogComponent, {
        data: obj, panelClass: 'tutorial-dialog-cls',
        disableClose: true, id: 'tutorial-dlg'
      });
    } else {
      this.matDialog.open(TutorialDialogComponent, {
        data: obj, panelClass: 'tutorial-dialog-cls',
        disableClose: true, id: 'tutorial-dlg'
      });
    }
  }
  @HostListener('window:resize')
  onResize() {
    this.checkMobile();
    this.getHeaderMenu();
    this.getSideNavConfigOptions();
  }


}
